import { Box, Button, Divider, Grid, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';

import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { NewCurrency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { NIL_UUID } from '../../../utils/UUID';
import { ProjectConfigFormikType, ProjectConfigProductFormikType } from '../ProjectConfigFormik';

export const NewEmptyProjectConfigProduct = (): ProjectConfigProductFormikType => ({
  product: {
    id: null,
    option: null,
    allowIncompatibleMixBatchUnits: false,
    incompatibleMixBatchUnits: false,
  },
  quantity: 1,
  usage: null,

  // 'kind' and 'price' are required in the shared yup validation schema, but are not used in the formik type
  kind: Enums.QuoteProductKind.Primary,
  price: NewCurrency({ number: '0' }),
});

export const ProjectConfigProductLookup = (): JSX.Element => {
  const theme = useTheme();

  const formikBag = useFormikContext<ProjectConfigFormikType>();

  if (formikBag.values.plant.id === null) {
    return <div>Please select a plant</div>;
  }

  const formikProjectConfigProducts = formikBag.values.products;

  return (
    <FieldArray
      name='products'
      render={(arrayHelpers): JSX.Element => {
        const isAddDisabled = formikBag.values.products.some((p) => p.product.id === null);
        return (
          <>
            {formikProjectConfigProducts.map((fqcp, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={`project-config-product-products-grid-${fqcp.product.id ?? NIL_UUID}-${index}`}
                display='flex'
                flexDirection='column'
                gap='0.5rem'
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ApiLookupInput
                          formState={formikBag.values}
                          name={`products.${index}.product`}
                          label='Product*'
                          route={{
                            barrel: 'GET products',
                            args: (inputText): QueryRouteBarrelTypes['GET products']['args'] => {
                              const args = ConstructListQueryParams(inputText);
                              return {
                                ...args,
                                queryParams: {
                                  ...args.queryParams,
                                  perPage: args.queryParams.perPage + 40,
                                  filterBy: [
                                    ...(args.queryParams.filterBy ?? []),
                                    {
                                      operation: Enums.FilterOperation.Equals,
                                      name: 'plantId',
                                      value: formikBag.values.plant.id ?? '',
                                    },
                                  ],
                                },
                              };
                            },
                            options: {
                              enabled: formikBag.values.plant.id !== null,
                            },
                            transform: (pl): LookupInputOption[] =>
                              lookups({
                                ...pl,
                                label: (p): string => {
                                  if (p.incompatibleMixBatchUnits) {
                                    return `[UOM issue] ${p.alternateID}`;
                                  }
                                  return p.alternateID;
                                },
                                sublabels: (p) => [p.name],
                              }),
                          }}
                          onMatchChange={(productId): void => {
                            if (productId === null) {
                              SetFormikValue(
                                formikBag,
                                `products.${index}`,
                                NewEmptyProjectConfigProduct(),
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Input
                          name={`products.${index}.quantity`}
                          label='Quantity*'
                          type='number'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Input name={`products.${index}.usage`} label='Usage' type='textarea' />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    xs={1}
                    pt='0px !important'
                  >
                    <Button
                      onClick={(): void => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <Trash variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))}
            <Button
              disabled={isAddDisabled}
              onClick={(): void => {
                arrayHelpers.push(NewEmptyProjectConfigProduct());
              }}
              sx={{
                textTransform: 'none',
                color: theme.palette.SlabIndigo['700'],
              }}
            >
              <Box display='flex' gap='0.5rem'>
                <Add color={theme.palette.SlabIndigo['700']} />
                Add {formikProjectConfigProducts.length === 0 ? 'product' : 'another'}
              </Box>
            </Button>
          </>
        );
      }}
    />
  );
};
