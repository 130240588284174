import { Box, Divider, Typography } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import { SendMessageInput } from '../../generated-types/SendMessageInput/SendMessageInput';
import { UpdateType, useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { QuotePdfModalContent } from '../../pages/Quotes/components/QuotePdfModal';
import { SlabContext } from '../../SlabContext';
import { Base64 } from '../../types/Base64';
import { MutationRouteBarrelTypes } from '../../utils/ApiClient';
import { QueryError } from '../../utils/Query';
import { NIL_UUID } from '../../utils/UUID';
import { ErrorFullPage } from '../Error/ErrorFullPage';
import { Input } from '../Input/Input';
import { SlabDrawer } from '../SlabDrawer/SlabDrawer';
import { ToastHook } from '../Toast/useToast';
import { EmailComposer } from './EmailComposer';
import { EmailSchema, FormikEmail } from './EmailFormik';

export const MarkAsSentAndShowToast = async ({
  quoteId,
  markAsSentMutation,
  toastHook,
}: {
  quoteId: string;
  markAsSentMutation: UseMutationResult<
    Quote,
    QueryError,
    UpdateType<MutationRouteBarrelTypes['POST mark quote as sent']['args']>,
    unknown
  >;
  toastHook: ToastHook;
}): Promise<void> => {
  try {
    await markAsSentMutation.mutateAsync({
      args: { pathParams: { id: quoteId }, body: {} },
      schema: Yup.object(),
    });
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status !== 422) {
      // Ignore status transition failure if 422: that HTTP status indicates that the tenant
      // has no "is sent" quote status, so the user shouldn't be expecting an auto-transition.
      toastHook.showToast('success', 'Quote sent. Status update failed, update manually.');
      return;
    }
  }
  toastHook.showToast('success', 'Quote sent');
};

type NylasFlyoutProps = {
  quoteId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  onError: (error: QueryError) => void;
  onClose?: () => void;
};
/**
 * @description The NylasFlyout component is like the different drawer
 * components used for creating/editing any of the other domain objects.
 * The difference is that the component doesn't create/modify an object
 * in the database, it sends data to the server that then relays that data
 * to Nylas to be sent as an email with the quote's PDF attached to the
 * email message.
 */
export const NylasFlyout = ({
  quoteId,
  isOpen,
  setIsOpen: setIsOpenProp,
  onSuccess,
  onError,
  onClose,
}: NylasFlyoutProps): JSX.Element | null => {
  const [base64Attachment, setBase64Attachment] = useState<Base64>('');
  const ctx = useContext(SlabContext);
  const useNylasV3 = ctx.userInfo.hasFlags([Enums.FeatureFlagName.FeatureFlagNylasV3]);

  const {
    data: quote,
    isLoading: isLoadingQuote,
    isError: isErrorQuote,
  } = useSlabQuery(
    'GET quote by ID',
    {
      pathParams: {
        id: quoteId,
      },
    },
    {
      enabled: quoteId !== NIL_UUID,
    },
  );

  const {
    data: quoteProducts,
    isLoading: isLoadingQuoteProducts,
    isError: isErrorQuoteProducts,
  } = useSlabQuery(
    'GET quote products by quote ID',
    {
      pathParams: { id: quote?.id ?? NIL_UUID },
    },
    {
      enabled: quote !== undefined,
    },
  );

  const setIsOpen: Dispatch<SetStateAction<boolean>> = (value) => {
    setIsOpenProp(value);
    if (value === false) {
      onClose?.();
    }
  };

  const messageMutation = useNylasV3 ? 'POST nylas v3 message' : 'POST nylas message';
  const sendMessage = useSlabMutation(messageMutation, {
    onSuccess: () => {
      onSuccess();
      setIsOpen?.(false);
    },
    onError,
  });

  const isLoading = isLoadingQuote || isLoadingQuoteProducts;
  const isError = isErrorQuote || isErrorQuoteProducts;

  if (isLoading || quoteId === NIL_UUID) {
    return null;
  }

  if (quote === undefined || isError) {
    return <ErrorFullPage />;
  }

  const quoteWithProducts = NewQuote({ ...quote, products: quoteProducts ?? [] });

  const initialValues = FormikEmail({
    to:
      quoteWithProducts.contact === null || quoteWithProducts.contact.emailAddress === null
        ? undefined
        : [
            {
              name: quoteWithProducts.contact.fullName(),
              email: quoteWithProducts.contact.emailAddress,
            },
          ],
    file: {
      file: base64Attachment,
      fileName: quoteWithProducts.fileName(),
    },
  });

  return (
    <SlabDrawer isOpen={isOpen}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (message): Promise<void> => {
          const wireMessage = new SendMessageInput(_.merge(SendMessageInput.zero(), message));
          sendMessage.mutate({
            args: { body: wireMessage },
            schema: EmailSchema,
          });
        }}
        validationSchema={EmailSchema}
      >
        {(formikBag): JSX.Element => (
          <Box padding='5rem 3.5rem 5rem 3.5rem' display='flex' flexDirection='column' flexGrow={1}>
            <Typography variant='h1'>Send Quote</Typography>
            <Box gap='2rem' display='flex' flexDirection='column' flexGrow={1} paddingY='1.25rem'>
              <Typography variant='h2'>Compose Email</Typography>
              <EmailComposer
                base64Attachment={base64Attachment}
                formikBag={formikBag}
                setIsOpen={setIsOpen}
                isSending={sendMessage.isPending}
              />
              <Divider variant='fullWidth' component='div' flexItem />
              <Typography variant='h2'>Attached Quote Preview</Typography>
              <Input label='Filename*' name='file.fileName' />
              <QuotePdfModalContent
                quote={quoteWithProducts}
                setIsOpen={setIsOpen}
                onPdfChanged={setBase64Attachment}
                showCancelButton={false}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </SlabDrawer>
  );
};
