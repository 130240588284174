import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Popover,
  styled,
  Toolbar,
  useTheme,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { Printer, SearchNormal1, Setting2 } from 'iconsax-react';
import React from 'react';

import { Market } from '../../../generated-types/Market/Market';
import { Plant } from '../../../generated-types/Plant/Plant';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { DEFAULT_COLUMN_HEADER_FORMATTER } from '../../../pdf/components/PdfTable';
import { DataTableListPdf } from '../../../pdf/DataTableListPdf';
import { List } from '../../../utils/List';
import { randomUUID } from '../../../utils/UUID';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Selector, SelectorOption } from '../../Selector/Selector';
import { Column } from '../TableDataModel';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.SlabGray['500']}`,
  backgroundColor: theme.palette.SlabGray[0],
  '&:hover': {
    backgroundColor: theme.palette.SlabGray['50'],
  },
}));

const SearchIconWrapper = styled('div')(() => ({
  padding: '0.875rem',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.SlabGray['900'],
  '& .MuiInputBase-input': {
    padding: '1rem',
    paddingLeft: '3rem',
  },
}));

const marketOptsFromList = (marketList: List<Market>): SelectorOption[] => [
  {
    id: null,
    name: 'All markets',
  },
  ...marketList.items.map((m) => ({
    id: m.id,
    name: m.name,
  })),
];

const plantOptsFromList = (plantList: List<Plant>, marketId: string | null): SelectorOption[] => {
  // If a market is selected, filter down to plants in that market.
  const filteredPlants =
    marketId === null ? plantList.items : plantList.items.filter((p) => p.market.id === marketId);
  return [
    {
      id: null,
      name: 'All plants',
    },
    ...filteredPlants.map((p) => ({
      id: p.id,
      name: p.name,
    })),
  ];
};

type EnhancedTableToolbarProps<TRow extends { id: string }> = {
  onSearch: (arg0: string) => void;
  allColumns: ReadonlySet<Column<TRow>>;
  renderedColumns: ReadonlySet<Column<TRow>>;
  isFetchingRows: boolean;
  printOpts?: {
    getAllRows: () => Promise<TRow[]>;
    tableName: string;
  };
  onColumnChange: (columnId: string, isRendered: boolean) => void;
  plantSelectorOpts?: {
    enabled: boolean;
    selectedPlantId: string | null;
    onPlantChange?: (arg0: string | null) => void;
    plantOpts?: SelectorOption[];
  };
  marketSelectorOpts?: {
    enabled: boolean;
    selectedMarketId: string | null;
    onMarketChange?: (arg0: string | null) => void;
    marketOpts?: SelectorOption[];
  };
};

export const EnhancedTableToolbar = <TRow extends { id: string }>({
  onSearch,
  allColumns: allColumnsSet,
  renderedColumns,
  isFetchingRows,
  printOpts,
  onColumnChange,
  plantSelectorOpts,
  marketSelectorOpts,
}: EnhancedTableToolbarProps<TRow>): JSX.Element => {
  const theme = useTheme();

  const [colSearchString, setColSearchString] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isOpeningPdf, setIsOpeningPdf] = React.useState(false);

  const { data: plantList, isLoading: isLoadingPlantList } = useSlabQuery(
    'GET plants',
    {},
    { enabled: plantSelectorOpts !== undefined && plantSelectorOpts.plantOpts === undefined },
  );

  const { data: marketList, isLoading: isLoadingMarketList } = useSlabQuery(
    'GET markets',
    {},
    { enabled: marketSelectorOpts !== undefined && marketSelectorOpts.marketOpts === undefined },
  );

  const marketSelector = ((): JSX.Element | null => {
    if (isLoadingMarketList) {
      return <LoadingSpinner />;
    }

    if (marketSelectorOpts === undefined) {
      return null;
    }

    const marketOpts =
      marketSelectorOpts.marketOpts ?? marketOptsFromList(marketList ?? List.zero());

    if (marketOpts.length < 2) {
      // The only option is "All markets", so we don't need to show the selector.
      return null;
    }

    return (
      <Selector
        label='Select market'
        selectorOpts={marketOpts}
        selectedId={marketSelectorOpts.selectedMarketId}
        onSelectedId={(selectedMarketId): void => {
          // If a plant is currently selected that is outside of the current market, reset it.
          if (
            selectedMarketId !== null &&
            plantSelectorOpts !== undefined &&
            plantSelectorOpts.onPlantChange !== undefined &&
            plantSelectorOpts.selectedPlantId !== null &&
            plantList !== undefined
          ) {
            const curPlant = plantList.items.find(
              (p) => p.id === plantSelectorOpts.selectedPlantId,
            );
            if (curPlant?.market?.id !== selectedMarketId) {
              plantSelectorOpts.onPlantChange(null);
            }
          }
          marketSelectorOpts.onMarketChange?.(selectedMarketId ?? null);
        }}
      />
    );
  })();

  const plantSelector = ((): JSX.Element | null => {
    if (isLoadingPlantList) {
      return <LoadingSpinner />;
    }

    if (plantSelectorOpts === undefined) {
      return null;
    }

    const plantOpts =
      plantSelectorOpts?.plantOpts ??
      plantOptsFromList(plantList ?? List.zero(), marketSelectorOpts?.selectedMarketId ?? null);

    if (plantOpts.length < 2) {
      // The only option is "All plants", so we don't need to show the selector.
      return null;
    }

    return (
      <Selector
        label='Select plant'
        selectorOpts={plantOpts}
        selectedId={plantSelectorOpts.selectedPlantId}
        onSelectedId={(selectedPlantId): void => plantSelectorOpts.onPlantChange?.(selectedPlantId)}
      />
    );
  })();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setColSearchString('');
    setAnchorEl(null);
  };

  const isColumnPickerOpen = Boolean(anchorEl);
  const id = isColumnPickerOpen ? 'column-picker-popover' : undefined;

  const allColumns = [...allColumnsSet];
  const filteredColumns =
    colSearchString === ''
      ? allColumns
      : allColumns.filter((col) => col.label.toLowerCase().includes(colSearchString.toLowerCase()));

  return (
    <Box pb='1.25rem' bgcolor={theme.palette.SlabGray['50']}>
      <Toolbar
        sx={{
          borderRadius: '0.625rem',
          bgcolor: theme.palette.SlabGray[0],
          justifyContent: 'space-between',
          padding: '1rem',
          boxShadow: theme.shadows[1],
        }}
      >
        <Search>
          <SearchIconWrapper>
            <SearchNormal1
              variant='TwoTone'
              color={theme.palette.SlabIndigo['700']}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            />
          </SearchIconWrapper>
          <StyledInputBase placeholder='Search' onChange={(e): void => onSearch(e.target.value)} />
        </Search>
        <Box display='flex' gap='1rem'>
          {isFetchingRows && (
            <LoadingSpinner
              color='inherit'
              size={24}
              sx={{ margin: 'auto 8px' }}
              wrapInBox={false}
            />
          )}
          {marketSelector}
          {plantSelector}
          <IconButton onClick={handleClick}>
            <Setting2 variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
          </IconButton>
          {printOpts !== undefined && (
            <IconButton
              onClick={async (): Promise<void> => {
                setIsOpeningPdf(true);
                const pdfBlob = await pdf(
                  <DataTableListPdf
                    columns={[...renderedColumns].map((col) => ({
                      columnHeaderToPdf: DEFAULT_COLUMN_HEADER_FORMATTER,
                      ...col,
                    }))}
                    rows={await printOpts?.getAllRows()}
                    title={printOpts?.tableName ?? ''}
                  />,
                ).toBlob();
                const pdfDataUri = URL.createObjectURL(pdfBlob);
                window.open(pdfDataUri, '', '_blank');
                setIsOpeningPdf(false);
              }}
              disabled={isOpeningPdf}
            >
              <Printer variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
            </IconButton>
          )}
        </Box>
        <Popover
          id={id}
          open={isColumnPickerOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box padding='0.625rem'>
            <Search>
              <SearchIconWrapper>
                <SearchNormal1
                  variant='TwoTone'
                  color={theme.palette.SlabIndigo['700']}
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder='Search'
                onChange={(e): void => setColSearchString(e.target.value)}
              />
            </Search>
            <Box paddingTop='1rem'>
              {filteredColumns.map((col) => (
                <Box
                  key={randomUUID()}
                  display='flex'
                  alignItems='center'
                  paddingX='0.25rem'
                  paddingY='0.25rem'
                >
                  <FormControlLabel
                    label={col.label}
                    control={
                      <Checkbox
                        checked={renderedColumns.has(col)}
                        onChange={(_, checked): void => {
                          onColumnChange(col.id, checked);
                        }}
                      />
                    }
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Popover>
      </Toolbar>
    </Box>
  );
};
