// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class QuoteSummary {
  readonly id: string;
  readonly quoteNumber: string;
  readonly name: string | null;
  readonly revisionNumber: number;
  readonly isLatestRevision: boolean;
  readonly projectName: string;
  readonly companyName: string | null;
  readonly expirationDate: string | null;
  readonly bidDate: string | null;
  readonly statusName: string;
  readonly isApprovedToSend: boolean;
  readonly salesRepName: string;
  readonly revenue: string;
  readonly estimatedCuyd: number;
  readonly isArchived: boolean;
  readonly plantName: string;
  readonly plantId: string;
  readonly marketId: string;
  readonly projectId: string;
  readonly companyId: string | null;
  readonly contactId: string | null;
  readonly creationDate: string | null;
  readonly revisionDate: string | null;
  readonly externalID: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.quoteNumber = data.quoteNumber === undefined ? '' : data.quoteNumber;
    this.name = data.name === undefined ? null : data.name;
    this.revisionNumber = data.revisionNumber === undefined ? 0 : data.revisionNumber;
    this.isLatestRevision = data.isLatestRevision === undefined ? false : data.isLatestRevision;
    this.projectName = data.projectName === undefined ? '' : data.projectName;
    this.companyName = data.companyName === undefined ? null : data.companyName;
    this.expirationDate = data.expirationDate === undefined ? null : data.expirationDate;
    this.bidDate = data.bidDate === undefined ? null : data.bidDate;
    this.statusName = data.statusName === undefined ? '' : data.statusName;
    this.isApprovedToSend = data.isApprovedToSend === undefined ? false : data.isApprovedToSend;
    this.salesRepName = data.salesRepName === undefined ? '' : data.salesRepName;
    this.revenue = data.revenue === undefined ? '' : data.revenue;
    this.estimatedCuyd = data.estimatedCuyd === undefined ? 0 : data.estimatedCuyd;
    this.isArchived = data.isArchived === undefined ? false : data.isArchived;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
    this.projectId = data.projectId === undefined ? NIL_UUID : data.projectId;
    this.companyId = data.companyId === undefined ? null : data.companyId;
    this.contactId = data.contactId === undefined ? null : data.contactId;
    this.creationDate = data.creationDate === undefined ? null : data.creationDate;
    this.revisionDate = data.revisionDate === undefined ? null : data.revisionDate;
    this.externalID = data.externalID === undefined ? null : data.externalID;
  }

  static zero(): QuoteSummary {
    const zeroValues: ExcludeMethodsDeep<QuoteSummary> = {
      id: NIL_UUID,
      quoteNumber: '',
      name: null,
      revisionNumber: 0,
      isLatestRevision: false,
      projectName: '',
      companyName: null,
      expirationDate: null,
      bidDate: null,
      statusName: '',
      isApprovedToSend: false,
      salesRepName: '',
      revenue: '',
      estimatedCuyd: 0,
      isArchived: false,
      plantName: '',
      plantId: NIL_UUID,
      marketId: NIL_UUID,
      projectId: NIL_UUID,
      companyId: null,
      contactId: null,
      creationDate: null,
      revisionDate: null,
      externalID: null,
    };
    return new QuoteSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteSummary = (
  props: PartialDeep<QuoteSummary, { recurseIntoArrays: true }>,
): QuoteSummary => new QuoteSummary(props);

export const NewQuoteSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteSummary>, { recurseIntoArrays: true }>,
): QuoteSummary => new QuoteSummary(props);
