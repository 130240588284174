import { Grid, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { Duration } from 'luxon';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { Input, InputProps } from '../../../components/Input/Input';
import { InputDropdown, InputOption } from '../../../components/InputDropdown/InputDropdown';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import { LocalLookupInput } from '../../../components/LookupInput/LocalLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { AddressMap } from '../../../components/Map/AddressMap';
import {
  buildInitialDeliveryCosts,
  CalculateInitialPrice,
  ProductSection,
  ProductSectionProps,
} from '../../../components/ProductSection/ProductSection';
import { SingleUnitDurationInput } from '../../../components/SingleUnitDurationInput/SingleUnitDurationInput';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { Address, NewAddress } from '../../../generated-types/Address/Address';
import { Company } from '../../../generated-types/Company/Company';
import { DeliveryCost } from '../../../generated-types/DeliveryCost/DeliveryCost';
import { DistanceDurationPair } from '../../../generated-types/DistanceDurationPair/DistanceDurationPair';
import { DistanceResult } from '../../../generated-types/DistanceResult/DistanceResult';
import Enums from '../../../generated-types/Enums';
import { Plant } from '../../../generated-types/Plant/Plant';
import { Product } from '../../../generated-types/Product/Product';
import { NewProjectProduct } from '../../../generated-types/ProjectProduct/ProjectProduct';
import { ProjectStatus } from '../../../generated-types/ProjectStatus/ProjectStatus';
import { User } from '../../../generated-types/User/User';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { ByRole } from '../../../utils/AuthHelpers';
import { NullableCostString } from '../../../utils/Currency';
import { FormatDateString, ZERO_DURATION_STRING } from '../../../utils/DateHelpers';
import { isValidReference, lookups } from '../../../utils/DomainHelpers';
import { ClearFormikFields, SetFormikValue } from '../../../utils/FormikHelpers';
import { List } from '../../../utils/List';
import { NewDeliveryCostOrNullFromFormik } from '../../Plants/PlantFormik';
import { EmptyFormikProject, FormikProject, ProjectFormikType } from '../ProjectFormik';
import { DynamicCompanyLookup } from './DynamicCompanyLookup';
import { ForecastsSection } from './ForecastsSection';

export const RecalculateTotalDeliveryCost = (deliveryCosts: DeliveryCost | null): string =>
  NullableCostString({ cost: deliveryCosts?.calculateTotalDeliveryCost() ?? null });

/**
 * Generate lookup options for potential project-winning customers.
 */
export const WinnerOptions = ({
  currentContractors,
  companyList,
}: {
  currentContractors: ProjectFormikType['contractors'];
  companyList: List<Company>;
}): InputOption[] => {
  const currentCompanyIds = currentContractors
    .map((c) => c.company.id)
    .filter((id) => id !== null) as string[];
  const eligibleCompanies = companyList.items.filter((c) => currentCompanyIds.includes(c.id));

  const result = lookups({
    items: eligibleCompanies,
    label: (c) => c.name,
    count: eligibleCompanies.length,
  });

  return result;
};

const ProjectInformationSection = (): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: userList,
  } = useSlabQuery('GET users', {});

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET project statuses', {});

  const isLoading = isLoadingUsers || isLoadingStatuses;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorUsers || isErrorStatuses;
  const isDataUndefined = userList === undefined || statusList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const filteredUsers = ByRole(userList, Enums.RoleName.Salesperson).items.filter((u) =>
    u.isSelectable(),
  );
  const userLookups = lookups({
    items: filteredUsers,
    label: (u: User) => u.fullName(),
    count: filteredUsers.length,
  });

  const statusLookups = lookups({
    ...statusList,
    label: (p: ProjectStatus) => p.name,
    compare: null,
  });

  const winningCompanyLookups: LookupInputOption[] = formikBag.values.contractors
    .map((c) => c.company.option)
    .flatMap((opt) => (opt != null ? [opt] : []));

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='name' label='Project Name*' />
        </Grid>
        <Grid item xs={6}>
          <InputDropdown name='user.id' label='Salesperson*' options={userLookups} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='owner' label='Owner' />
        </Grid>
        <Grid item xs={6}>
          <InputDropdown name='projectStatus.id' label='Status*' options={statusLookups} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalLookupInput
            formState={formikBag.values}
            name='winningCompany.company'
            label='Project winner'
            options={winningCompanyLookups}
          />
        </Grid>
        <Grid item xs={6}>
          <Input name='confidence' label='Confidence %' type='number' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            label='Est. Start Date'
            name='estimatedStartDate'
            type='date'
            max={
              formikBag.values.estimatedEndDate !== null
                ? FormatDateString(formikBag.values.estimatedEndDate)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label='Est. End Date'
            name='estimatedEndDate'
            type='date'
            min={
              formikBag.values.estimatedStartDate !== null
                ? FormatDateString(formikBag.values.estimatedStartDate)
                : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input label='Bid Date' name='bidDate' type='date' />
        </Grid>
        <Grid item xs={6}>
          <Input label='Expiration Date' name='expirationDate' type='date' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input label='Competitor' name='competitor' />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            label='Tax Code'
            name='taxCode'
            route={{
              barrel: 'GET tax codes',
              args: ConstructListQueryParams,
              transform: (taxCodeList): LookupInputOption[] =>
                lookups({
                  ...taxCodeList,
                  items: taxCodeList.items,
                  label: (t) => t.name,
                  sublabels: (t) => [t.code],
                }),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            label='Segment'
            name='segment'
            route={{
              barrel: 'GET segments',
              args: ConstructListQueryParams,
              transform: (segmentList): LookupInputOption[] =>
                lookups({
                  ...segmentList,
                  items: segmentList.items,
                  label: (t) => t.name,
                }),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
   AddressSelectionProps is an interface that represents the update hook that is returned from the PlantSectionClosure
   and called when the address is updated in the AddressMap component
 */
interface AddressSelectionProps {
  addressUpdatedHook: {
    hook: Dispatch<SetStateAction<Address | undefined>>;
  };
}

/**
   ProjectAddressSectionClosure is a closure around the ProjectAddressSection that wraps the update hook that is
   returned by the PlantSectionClosure function
 */
const ProjectAddressSectionClosure =
  ({ addressUpdatedHook }: AddressSelectionProps): (() => JSX.Element) =>
  () => <AddressMap onAddressUpdated={addressUpdatedHook.hook} name='address' isInProject />;

/**
   PlantRefWithDistance is an interface that is used to represent the plant lookup options to the InputLookup
   component and encapsulate the plant information along with the queried distance and drive time information.
 */
interface PlantRefWithDistance {
  id: string;
  plant: Plant;
  duration: DistanceDurationPair | null;
}

/**
   getPlantDropdownSublabel takes a PlantRefWithDistance object and returns a string
   representation of the distance and drive time to be used in the dropdown's sublabel.
 */
const getPlantDropdownSublabel = (plantWithDistance: PlantRefWithDistance): string => {
  if (plantWithDistance.duration !== null) {
    return `${plantWithDistance.duration.distance.text}
  | ${plantWithDistance.duration.duration.value.toFormat("h' hours' m' minutes'") ?? ''}`;
  }
  return '';
};

/**
   PlantSelectionComponentAndHook is an interface representing the return value of PlantSectionClosure.
   The type holds the JSX component to be rendered as well as an object with a reference to the update
   hook function defined inside the closure to be passed to ProjectAddressSectionClosure.
 */
interface PlantSelectionComponentAndHook {
  component: () => JSX.Element;
  addressUpdatedHook: {
    hook: Dispatch<SetStateAction<Address | undefined>>;
  };
}

/**
   PlantSectionClosure is a closure around the PlantSection component that also returns an object
   that references a function defined inside of the closure that is to be passed to the ProjectAddressSectionClosure
   component so that the hook is called when the address is updated.
 */
const PlantSectionClosure = (): PlantSelectionComponentAndHook => {
  const setProjectAddress: { hook: Dispatch<SetStateAction<Address | undefined>> } = {
    hook: () => {},
  };
  return {
    component: (): JSX.Element => {
      const theme = useTheme();
      const formikBag = useFormikContext<ProjectFormikType>();
      const [projectAddress, setAddress] = useState<Address | undefined>(
        NewAddress(formikBag?.values?.address),
      );
      /**
         distanceQueryEnabled is a state variable that is true when a new distance query must be run.
         The state is true by default so that the query is run with the original data on the first mounting
         during page load. The rest of its lifetime is controlled by an effect hook that is called when
         projectAddress changes.
       */
      const [distanceQueryEnabled, setDistanceQueryEnabled] = useState<boolean>(true);
      const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false);

      const {
        isLoading: isLoadingPlants,
        isError: isErrorPlants,
        data: rawPlantList,
      } = useSlabQuery('GET plants', {});

      const plantList: List<Plant> = rawPlantList ?? List.zero();

      /**
         An effect hook to manage the enabled/disabled state of the distance query. Any time that there is a
         change to projectAddress (e.g. when setAddress is called from the closed setProjectAddress.hook reference)
         such that the address and the address's lat and lng are defined the query should be enabled. Otherwise, it
         should be disabled.
       */
      useEffect(() => {
        if (
          projectAddress !== undefined &&
          projectAddress.latitude !== null &&
          projectAddress.latitude !== undefined &&
          projectAddress.longitude !== null &&
          projectAddress.longitude !== undefined
        ) {
          setDistanceQueryEnabled(true);
        } else {
          setDistanceQueryEnabled(false);
        }
      }, [projectAddress]);

      /**
         The server expects a '|' separated list of addresses. A lat/lng pair is used as the address here because
         the distance API function might return an origin address that is altered from the one that was passsed, so
         we elect to send the least ambiguious location reference and do not reference the results by the addresses
         but by the ordering of the items in the returned matrix.
       */
      const originList = plantList.items
        ?.map((p) => p.address.toLatLngPairString())
        .filter((p) => p !== undefined);

      const destination =
        projectAddress !== undefined && projectAddress.toLatLngPairString() !== undefined
          ? projectAddress.toLatLngPairString()
          : '';

      const {
        isLoading: isLoadingDistance,
        isError: isErrorDistance,
        data: plantDistancesRaw,
      } = useSlabQuery(
        'GET maps distance from points',
        {
          queryParams: {
            origins: originList.join('|') ?? '',
            destination: destination ?? '',
          },
        },
        {
          enabled: distanceQueryEnabled && (originList?.length ?? 0) > 0 && destination !== '',
          retry: (n, err): boolean => {
            // Don't retry on a client error or when no results are found
            if (err.response?.status === 404 || err.response?.status === 400) {
              return false;
            }
            // Do retry on an API/server error
            return true;
          },
        },
      );

      // TODO SC-621: Refactor/extract function and add tests
      const plantDistances = plantDistancesRaw ?? DistanceResult.zero();

      /**
         Merge the list of plants with both latitude and longitude against the results
         from the direction query into a list of PlantRefWithDistance objects that is
         sorted by the distance the plant is from the project's address to be used in the InputLookup
         component. This is updated any time that the project's address changes.
       */
      const plantsWithDistances =
        plantList.items
          .filter((p) => p.address.latitude !== null && p.address.longitude !== null)
          .map((p, n) => ({
            id: p.id,
            plant: p,
            duration: plantDistances.resultByIndex(n, 0),
          }))
          .sort(
            (a, b) =>
              (a.duration?.distance?.meters ?? Number.MIN_VALUE) -
              (b.duration?.distance?.meters ?? Number.MIN_VALUE),
          ) ?? null;

      const plantsAndSortedDistancesPerPlant = [
        ...plantsWithDistances,
        ...plantList.items
          .filter((p) => p.address.latitude === null || p.address.longitude === null)
          .map((p) => ({ id: p.id, plant: p, duration: null })),
      ];

      const plantLookups = lookups({
        items: plantsAndSortedDistancesPerPlant,
        label: (p) => p.plant.name,
        sublabels: (p) => [getPlantDropdownSublabel(p)],
        compare: null,
        count: plantsAndSortedDistancesPerPlant.length,
      });

      useEffect(() => {
        if (isAddressChanged && !isLoadingDistance) {
          const distanceAndDuration = plantsAndSortedDistancesPerPlant.find(
            (i) => i.id === formikBag.values.plant.id,
          )?.duration;
          const distance = distanceAndDuration?.distance?.miles();
          const duration = distanceAndDuration?.duration?.value.toISO() ?? null;
          SetFormikValue(
            formikBag,
            'plantDistanceMiles',
            distance === undefined ? null : String(distance),
          );
          SetFormikValue(formikBag, 'plantDriveTime', duration);
          setIsAddressChanged(false);
        }
      }, [plantDistancesRaw]);

      // TODO: #2543 this should a `DistanceAndDuration | null` - if null, clear the values,
      // otherwise set the appropriate formik field values at the call site.
      const onPlantChange = (plantId: string | null): void => {
        if (formikBag.values.plant.id !== plantId) {
          /* Empty the products dropdown, the plant distance, and the plant drive time if the plant changes */
          ClearFormikFields(formikBag, EmptyFormikProject, 'projectConfig', 'products');
        }
        if (
          plantsAndSortedDistancesPerPlant.length > 0 &&
          plantsAndSortedDistancesPerPlant.some((p) => p.duration?.duration !== undefined)
        ) {
          /* When a new plant is selected, set the drive time and distance to the plant in the formik values */
          const distanceAndDuration = plantsAndSortedDistancesPerPlant.find(
            (i) => i.id === plantId,
          )?.duration;
          const distance = distanceAndDuration?.distance?.miles();
          SetFormikValue(
            formikBag,
            'plantDistanceMiles',
            distance === undefined ? null : String(distance),
          );
          const duration = distanceAndDuration?.duration?.value.toISO() ?? null;
          SetFormikValue(formikBag, 'plantDriveTime', duration);
        }
      };

      /* Assign the setAddress state update function to the closure object's hook field */
      setProjectAddress.hook = (address: SetStateAction<Address | undefined>): void => {
        setAddress(address);
        if (formikBag.values.plant.id !== null) {
          setIsAddressChanged(true);
          SetFormikValue(formikBag, 'hasDistanceChanged', true);
        }
      };

      const isLoading = isLoadingPlants || isLoadingDistance;
      const isError = isErrorPlants || isErrorDistance;

      if (isLoading) {
        return <LoadingSpinner />;
      }

      if (isError) {
        return <div>ERROR</div>;
      }

      return (
        <Box display='flex' flexDirection='column' gap='1rem'>
          <Box paddingBottom='0.5rem'>
            <Typography variant='h6' color={theme.palette.warning.main}>
              Warning: Changing the plant will clear all currently selected products.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalLookupInput
                formState={formikBag.values}
                name='plant'
                label='Plant*'
                options={plantLookups}
                onMatchChange={onPlantChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                name='plantDistanceMiles'
                label='Distance'
                type='number'
                endAdornment='miles'
                disabled
                onInputChange={async (plantDistanceMiles): Promise<void> => {
                  // Also set the DeliveryCost driving distances for each of the project's products.
                  formikBag.values.products.forEach((p, index) => {
                    const deliveryCosts = NewDeliveryCostOrNullFromFormik(p.deliveryCosts, {
                      driveDistanceToJob: plantDistanceMiles,
                      driveDistanceToPlant: plantDistanceMiles,
                    });
                    SetFormikValue(formikBag, `products.${index}.deliveryCosts.displayOnly`, {
                      totalDeliveryCost: RecalculateTotalDeliveryCost(deliveryCosts),
                    });
                    // TODO need to recalculate suggested price, too
                    SetFormikValue(
                      formikBag,
                      `products.${index}.deliveryCosts.driveDistanceToJob`,
                      plantDistanceMiles,
                    );
                    SetFormikValue(
                      formikBag,
                      `products.${index}.deliveryCosts.driveDistanceToPlant`,
                      plantDistanceMiles,
                    );
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SingleUnitDurationInput
                name='plantDriveTime'
                unit='minutes'
                label='Drive time'
                disabled
                onInputChange={async (plantDriveTime): Promise<void> => {
                  // Also set the DeliveryCost driving times for each of the project's products.
                  formikBag.values.products.forEach((p, index) => {
                    const deliveryCosts = NewDeliveryCostOrNullFromFormik(p.deliveryCosts, {
                      driveToJobTime: plantDriveTime,
                      driveToPlantTime: plantDriveTime,
                    });
                    SetFormikValue(formikBag, `products.${index}.deliveryCosts.displayOnly`, {
                      totalDeliveryCost: RecalculateTotalDeliveryCost(deliveryCosts),
                    });
                    // TODO need to recalculate suggested price, too
                    SetFormikValue(
                      formikBag,
                      `products.${index}.deliveryCosts.driveToJobTime`,
                      plantDriveTime,
                    );
                    SetFormikValue(
                      formikBag,
                      `products.${index}.deliveryCosts.driveToPlantTime`,
                      plantDriveTime,
                    );
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    },
    /* The object that contains the reference to the hook that will be passed to the address component */
    addressUpdatedHook: setProjectAddress,
  };
};

/**
   This small section calls the two closure components, gets the returned function from the plant section
   and passes the function to the address section's closure and assigned the returned JSX components to the
   correct variables that are referenced below.
 */
const plantSectionAndAddressHook = PlantSectionClosure();
const PlantSection = plantSectionAndAddressHook.component;
const { addressUpdatedHook } = plantSectionAndAddressHook;
const ProjectAddressSection = ProjectAddressSectionClosure({ addressUpdatedHook });

const ProjectConfigSection = ({
  curProductsRef,
}: {
  curProductsRef: React.MutableRefObject<Record<number, Product | null>>;
}): JSX.Element => {
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const theme = useTheme();
  const formikBag = useFormikContext<ProjectFormikType>();

  const {
    data: plant,
    isLoading: isLoadingPlant,
    isError: isErrorPlant,
  } = useSlabQuery(
    'GET plant by ID',
    {
      pathParams: {
        id: formikBag.values.plant?.id ?? '',
      },
    },
    {
      enabled: formikBag.values.plant.id !== null,
    },
  );

  const {
    data: projectConfig,
    isLoading: isLoadingProjectConfig,
    isError: isErrorProjectConfig,
  } = useSlabQuery(
    'GET project config by ID',
    {
      pathParams: {
        id: formikBag.values.projectConfig?.id ?? '',
      },
    },
    {
      enabled: formikBag.dirty && (formikBag.values.projectConfig?.id ?? null) !== null,
    },
  );

  const {
    data: projectConfigProducts,
    isLoading: isLoadingProjectConfigProducts,
    isError: isErrorProjectConfigProducts,
  } = useSlabQuery(
    'GET project config products by project config ID',
    {
      pathParams: {
        id: formikBag.values.projectConfig?.id ?? '',
      },
    },
    {
      enabled: formikBag.dirty && (formikBag.values.projectConfig?.id ?? null) !== null,
    },
  );

  // This useEffect handles the update of the curProductsRef and formik state values
  // when the project config changes.
  useEffect((): void => {
    // If there are no changes to the form yet, do not overwrite the project config.
    if (!formikBag.dirty) {
      return;
    }

    // If the project config is empty, or recently set to empty, do not change anything.
    if ((formikBag.values.projectConfig?.id ?? '') === '') {
      return;
    }

    // If the plant is not yet loaded, wait for it.
    if (isLoadingPlant || plant === undefined) {
      return;
    }

    // If the project config or products are loading, wait for them to finish.
    if (isLoadingProjectConfig || isLoadingProjectConfigProducts) {
      return;
    }

    // An error occurred trying to fetch the project config or products, so Log an error,
    // and clear the value to allow them to retry.
    if (
      isErrorProjectConfig ||
      isErrorProjectConfigProducts ||
      isErrorPlant ||
      projectConfig === undefined ||
      projectConfigProducts === undefined
    ) {
      LogRocket.error('Error fetching plant, project config or products');
      SetFormikValue(formikBag, 'projectConfig', { id: null, option: null });
      return;
    }

    // Construct the delivery cost to apply to each of the new products
    const deliveryCosts = buildInitialDeliveryCosts({
      deliveryCosts: plant.deliveryCosts,
      drivingTime: Duration.fromISO(formikBag.values.plantDriveTime ?? ZERO_DURATION_STRING),
      drivingDistance: formikBag.values.plantDistanceMiles,
    });

    // Construct a temporary formik project using the current delivery cost values for product
    // cost and price calculations.
    const tempFormikProject = FormikProject(
      {
        estimatedVolumeOverride: projectConfig.estimatedVolumeOverride,
        revenueOverride: projectConfig.revenueOverride,

        companies: projectConfig.companies,

        products: projectConfigProducts.map((pcp) =>
          NewProjectProduct({
            product: pcp.product,
            quantity: pcp.quantity,
            usage: pcp.usage,
            deliveryCosts,
            price: CalculateInitialPrice({
              plantProduct: pcp.product,
              projectProduct: undefined,
              deliveryCosts,
            }),
          }),
        ),
      },
      // We can safely use empty values for the custom field definition list because we will
      // not be overwriting the custom field values.
      [],
      usesDispatchCustomer,
    );

    // Set the current formik values accordingly.
    SetFormikValue(formikBag, 'estimatedVolumeOverride', tempFormikProject.estimatedVolumeOverride);
    SetFormikValue(formikBag, 'revenueOverride', tempFormikProject.revenueOverride);
    SetFormikValue(formikBag, 'contractors', tempFormikProject.contractors);
    SetFormikValue(formikBag, 'otherCompanies', tempFormikProject.otherCompanies);
    SetFormikValue(formikBag, 'products', tempFormikProject.products);

    // We now update the ProjectFormik state for the newly selected values.
    curProductsRef.current = projectConfigProducts.reduce(
      (acc, p, idx) => ({ ...acc, [idx]: p.product }),
      {},
    );
  }, [formikBag.values.projectConfig?.id, projectConfig, projectConfigProducts, plant]);

  const projectConfigDisabled = !isValidReference(formikBag.values.plant);
  const projectConfigLabel = projectConfigDisabled ? 'Select a plant' : 'Configuration template';

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Box paddingBottom='0.5rem'>
        <Typography variant='h6' color={theme.palette.warning.main}>
          Warning: Selecting a new project config will update contractors, other companies,
          products, and override details.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ApiLookupInput
            formState={formikBag.values}
            name='projectConfig'
            label={projectConfigLabel}
            disabled={projectConfigDisabled}
            route={{
              barrel: 'GET project config summaries',
              args: (inputText): QueryRouteBarrelTypes['GET project config summaries']['args'] => {
                const args: QueryRouteBarrelTypes['GET project config summaries']['args'] = {
                  queryParams: {
                    page: 0,
                    perPage: DEFAULT_LOOKUP_LENGTH,
                    filterBy: [
                      {
                        name: 'plantId',
                        operation: Enums.FilterOperation.Equals,
                        value: formikBag.values.plant.id ?? '',
                      },
                    ],
                  },
                };
                if (inputText.trim() !== '' && args.queryParams?.filterBy !== undefined) {
                  args.queryParams.filterBy.push({
                    operation: Enums.FilterOperation.Lookup,
                    value: inputText,
                  });
                }
                return args;
              },
              transform: (projectConfigSummaryList): LookupInputOption[] =>
                lookups({
                  ...projectConfigSummaryList,
                  label: (t) => t.name,
                }),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
const DetailsSection = (): JSX.Element => (
  <Box display='flex' flexDirection='column' gap='1rem'>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Input name='revenueOverride' label='Revenue Override' type='currency' startAdornment='$' />
      </Grid>
      <Grid item xs={6}>
        <Input name='estimatedVolumeOverride' label='Estimated CUYD Override' type='number' />
      </Grid>
    </Grid>
  </Box>
);

type ProductsSectionProps = {
  curProductsRef: ProductSectionProps['curProductsRef'];
};

const ProductLookupSection = ({ curProductsRef }: ProductsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();
  const {
    data: plant,
    isLoading: isLoadingPlant,
    isError: isErrorPlant,
  } = useSlabQuery(
    'GET plant by ID',
    {
      pathParams: {
        id: formikBag.values.plant.id ?? '',
      },
    },
    {
      enabled: formikBag.values.plant.id !== null,
    },
  );

  if (isLoadingPlant) {
    return <LoadingSpinner />;
  }

  if (isErrorPlant || plant === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <ProductSection
      sectionType={Enums.QuoteProductKind.Primary}
      plant={plant}
      curProductsRef={curProductsRef}
      project={null}
      projectProductsByProductId={{}}
    />
  );
};

const ProjectForecastsSection = (initialForecastYear: number): JSX.Element => (
  <ForecastsSection initialForecastYear={initialForecastYear} />
);

const NoteSection = (): JSX.Element => (
  <Box>
    <Input type='textarea' name='notes' />
  </Box>
);

const CustomFieldsSection = (): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();

  // TODO: #1237 this should no longer be needed.
  const inputType = (wireType: string): Pick<InputProps, 'type'> => {
    if (wireType === 'string') {
      return { type: 'text' };
    }
    return { type: wireType.toLowerCase() } as unknown as Pick<InputProps, 'type'>;
  };

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <FieldArray
        name='customFields'
        render={(): JSX.Element => (
          <>
            {_.chunk(formikBag.values.customFields, 2).map((cfChunk, chunkIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={`custom-field-chunk-${chunkIdx}`} container spacing={2}>
                {cfChunk.map((cf, cfIdx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={`custom-field-input-${chunkIdx * 2 + cfIdx}`} item xs={6}>
                    {cf.definition.options === null ? (
                      <Input
                        name={`customFields.${chunkIdx * 2 + cfIdx}.value`}
                        label={cf.definition.name}
                        type={inputType(cf.definition.type).type}
                      />
                    ) : (
                      <LocalLookupInput
                        formState={formikBag.values}
                        name={`customFields.${chunkIdx * 2 + cfIdx}.value`}
                        label={cf.definition.name}
                        options={cf.definition.options.map((opt) => ({
                          label: opt.label,
                          value: String(opt.value),
                        }))}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            ))}
          </>
        )}
      />
    </Box>
  );
};

export type ProjectSection =
  | 'Project Information*'
  | 'Additional Information'
  | 'Job Site Address*'
  | 'Plant Selection*'
  | 'Contractors'
  | 'Other companies'
  | 'Product(s) info'
  | 'Details'
  | 'Forecasts'
  | 'Note';

const allSteps: SlabStep<ProjectFormikType>[] = [
  {
    label: 'Project Information*',
    content: ProjectInformationSection,
    maybeErrorFieldNames: ['name', 'projectStatus', 'user'],
  },
  {
    label: 'Additional Information',
    content: CustomFieldsSection,
    maybeErrorFieldNames: [],
  },
  {
    label: 'Job Site Address*',
    content: ProjectAddressSection,
    maybeErrorFieldNames: ['address'],
  },
  {
    label: 'Plant Selection*',
    content: PlantSection,
    maybeErrorFieldNames: ['plant'],
  },
  {
    label: 'Project Config',
    content: ({ curProductsRef }) => ProjectConfigSection({ curProductsRef }),
    maybeErrorFieldNames: [],
  },
  {
    label: 'Contractors',
    content: () => DynamicCompanyLookup({ sectionType: 'contractors' }),
    maybeErrorFieldNames: ['contractors'],
  },
  {
    label: 'Other companies',
    content: () => DynamicCompanyLookup({ sectionType: 'otherCompanies' }),
    maybeErrorFieldNames: ['otherCompanies'],
  },
  {
    label: 'Product(s) info',
    content: ({ curProductsRef }) => ProductLookupSection({ curProductsRef }),
    maybeErrorFieldNames: ['products'],
  },
  {
    label: 'Details',
    content: DetailsSection,
    maybeErrorFieldNames: [],
  },
  {
    label: 'Forecasts',
    content: ({ initialForecastYear }) => ProjectForecastsSection(initialForecastYear),
    maybeErrorFieldNames: [],
  },
  {
    label: 'Note',
    content: NoteSection,
    maybeErrorFieldNames: [],
  },
];

type ProjectDrawerSectionsProps = {
  initialSection: ProjectSection;
  initialForecastYear: number;
  curProductsRef: ProductSectionProps['curProductsRef'];
  omitCustomFieldSection: boolean;
};

export const ProjectDrawerSections = ({
  initialSection,
  initialForecastYear,
  curProductsRef,
  omitCustomFieldSection,
}: ProjectDrawerSectionsProps): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();

  const steps = allSteps.filter((s): boolean => {
    if (omitCustomFieldSection && s.label === 'Additional Information') {
      return false;
    }
    return true;
  });

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <SlabStepper
      steps={steps.map((s) => ({
        ...s,
        content: () =>
          s.content({
            initialForecastYear,
            curProductsRef,
          }),
      }))}
      activeStepHook={stepStateHook}
      isStepDisabled={(step: SlabStep<ProjectFormikType>): boolean =>
        ['Product(s) info', 'Project Config'].includes(step.label) &&
        formikBag.values.plant.id === null
      }
    />
  );
};
