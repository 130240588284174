import { InfoRounded } from '@mui/icons-material';
import { Alert, Box, Grid, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Activities } from '../../components/Activities/Activities';
import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FilterLabel } from '../../components/DataTable/components/FilterTabs';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { ApiTableDataParams, useApiTableData } from '../../components/DataTable/useApiTableData';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { CompanyCategoryAgg } from '../../generated-types/CompanyCategoryAgg/CompanyCategoryAgg';
import { CompanySummary } from '../../generated-types/CompanySummary/CompanySummary';
import Enums from '../../generated-types/Enums';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { SlabTheme } from '../../theme';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { CompanyDrawer } from './CompanyDrawer';

type CompanyListAggregations = {
  id: string;
  count: number;
};

const buildApiTableDataModelConfig = (
  usesDispatchCustomer: boolean,
): ApiTableDataParams<
  CompanySummary,
  'GET company summaries',
  QueryRouteBarrelTypes['GET company summaries'],
  CompanyListAggregations,
  'GET company category aggregations',
  QueryRouteBarrelTypes['GET company category aggregations']
> => {
  const extractAggregations = (
    stats: CompanyCategoryAgg[] = [],
  ): { [id: string]: CompanyListAggregations } => {
    const aggregationConfig: { [id: string]: CompanyListAggregations } = Object.fromEntries(
      Object.values(Enums.CompanyCategory).map((companyCategory) => [
        companyCategory,
        {
          id: companyCategory,
          count: 0,
        },
      ]),
    );
    stats.forEach((stat) => {
      aggregationConfig[stat.companyCategory].count = stat.count;
    });
    aggregationConfig[''] = {
      id: '',
      count: stats.reduce((sum: number, stat: CompanyCategoryAgg) => sum + stat.count, 0),
    };
    return aggregationConfig;
  };

  const columns: ColumnConfig<CompanySummary, NestedKeyOf<CompanySummary>>[] = [
    {
      id: 'category',
      label: 'Category',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/companies/${row.id}` }),
    },
    {
      id: 'streetAddress',
      label: 'Address',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'city',
      label: 'City',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'state',
      label: 'State',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'marketName',
      label: 'Market',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v): JSX.Element => {
        if (v === undefined || v === null || v === '') {
          return <Typography color={SlabTheme.palette.SlabGray['400']}>[not set]</Typography>;
        }
        return <Typography>{v}</Typography>;
      },
    },
  ];

  const dispatchCustomerColumns: ColumnConfig<CompanySummary, NestedKeyOf<CompanySummary>>[] = [
    {
      id: 'creditStatusCode',
      label: 'Credit Code',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'creditStatusDescription',
      label: 'Credit Description',
      type: 'string',
      isDisplayed: true,
    },
  ];

  if (usesDispatchCustomer) {
    columns.push(...dispatchCustomerColumns);
  }

  const makeAggregationArgs = (
    rowsArgs: QueryRouteBarrelTypes['GET company summaries']['args'],
  ): QueryRouteBarrelTypes['GET company category aggregations']['args'] => {
    const filterBy = (rowsArgs.queryParams?.filterBy ?? []).filter(
      (param) => param.operation !== 'equals' || !(param.name as string).startsWith('category'),
    );
    if (!filterBy.length) {
      return {};
    }
    return { queryParams: { filterBy } };
  };

  return {
    rowsRouteKey: 'GET company summaries',
    extractRows: (companyList) => companyList.items,
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'name',
    },
    aggregationsRouteKey: 'GET company category aggregations',
    extractAggregations,
    makeColumnConfigs: () => columns,
    makeAggregationArgs,
  };
};

export const CompanyList = (): JSX.Element => {
  const navigate = useNavigate();
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const [companyId, setCompanyId] = useState<string | null>(null);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const {
    data: listDispatchCustomers,
    isLoading: listDispatchCustomersLoading,
    isError: listDispatchCustomersError,
  } = useSlabQuery(
    'GET dispatch customer summaries',
    {
      queryParams: {
        page: 0,
        perPage: 1,
      },
    },
    { enabled: usesDispatchCustomer },
  );

  const {
    data: connectedCompanies,
    isLoading: connectedCompaniesLoading,
    isError: connectedCompaniesError,
  } = useSlabQuery(
    'GET company summaries',
    {
      queryParams: {
        page: 0,
        perPage: 1,
        filterBy: [
          {
            name: 'isConnectedToDispatchCustomer',
            operation: Enums.FilterOperation.Equals,
            value: 'true',
          },
        ],
      },
    },
    { enabled: usesDispatchCustomer },
  );

  const { toastHook, ...companyDrawerProps } = useDrawerManager({
    baseUrl: '/companies',
    resourceTypeName: 'company',
    drawerProps: {
      resourceId: companyId,
    },
  });

  const tableModel = useApiTableData(buildApiTableDataModelConfig(usesDispatchCustomer));

  const filterLabels: FilterLabel<CompanySummary, CompanyListAggregations>[] = [
    {
      label: 'All',
      filter: null,
      resultCount: (aggregations) => aggregations[''].count,
    },
    ...Object.values(Enums.CompanyCategory).map(
      (companyCategory): FilterLabel<CompanySummary, CompanyListAggregations> => ({
        label: companyCategory,
        filter: {
          name: 'category',
          operation: Enums.FilterOperation.Equals,
          value: companyCategory,
        },
        resultCount: (aggregations) => aggregations[companyCategory].count,
      }),
    ),
  ];

  const currentDate = DateTime.now();

  const loading = listDispatchCustomersLoading || connectedCompaniesLoading;

  if (loading) {
    return <LoadingSpinner />;
  }

  const error = listDispatchCustomersError || connectedCompaniesError;
  const isDataUndefined =
    usesDispatchCustomer &&
    (listDispatchCustomers === undefined || connectedCompanies === undefined);

  if (error || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const totalConnectedCompanies = connectedCompanies?.count ?? 0;
  const totalDispatchCustomers = listDispatchCustomers?.count ?? 0;
  const disconnectedDispatchCustomers = totalDispatchCustomers - totalConnectedCompanies;

  return (
    <Page title='Companies'>
      {toastHook.toast}
      <CompanyDrawer
        {...companyDrawerProps}
        onSave={(company): void => {
          if (companyId === null) {
            navigate(`/companies/${company.id}`);
          } else {
            companyDrawerProps.setIsOpen(false);
            toastHook.showToast('success');
          }
        }}
      />
      <Activities
        currentDate={currentDate}
        isDrawerOpen={isActivityDrawerOpen}
        setIsDrawerOpen={setIsActivityDrawerOpen}
        activityId={activityId}
        setActivityId={setActivityId}
        drawerOnly
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Companies</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='add activity'
            variant='secondary'
            onClick={(): void => {
              setActivityId(null);
              setIsActivityDrawerOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='create company'
            variant='primary'
            onClick={(): void => {
              setCompanyId(null);
              companyDrawerProps.setIsOpen(true);
            }}
            icon='add'
          />
        </Box>
      </Box>

      {usesDispatchCustomer && disconnectedDispatchCustomers > 0 && (
        <Grid item xs={9} paddingBottom='2rem'>
          <Alert
            severity='info'
            icon={
              <Tooltip
                enterDelay={200}
                placement='bottom-start'
                arrow
                title='Click CREATE COMPANY to see the dispatch customers available in the dropdown'
              >
                <InfoRounded />
              </Tooltip>
            }
          >
            You have {disconnectedDispatchCustomers} disconnected dispatch customers
          </Alert>
        </Grid>
      )}

      <Box height='400px'>
        <DataTable
          tableModel={tableModel}
          onEditPress={(row): void => {
            setCompanyId(row.id);
            companyDrawerProps.setIsOpen(true);
          }}
          tabFilters={filterLabels}
          includePrint={{ title: 'Companies' }}
          marketSelectorOpts={{ enabled: true }}
        />
      </Box>
    </Page>
  );
};
