import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import { QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext, SlabContextType } from '../../SlabContext';
import { ArrayToIndexedRecord, DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification, onSubmitHandler } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { NewDeliveryCostOrNullFromFormik } from '../Plants/PlantFormik';
import { QuoteDrawerSections, QuoteSection } from './components/QuoteDrawerSections';
import {
  FormikQuote,
  QuoteFormikType,
  QuoteSchemaFormik,
  QuoteSchemaWire,
  QuoteSubmitType,
} from './QuoteFormik';

export const formikQuoteToWire = (values: QuoteFormikType): Quote => {
  const products = [...values.mainProducts, ...values.additionalProducts].map((p) => ({
    ...p,
    deliveryCosts: NewDeliveryCostOrNullFromFormik(p.deliveryCosts),
  }));
  const priceEscalations = values.priceEscalations.map((qpe) => {
    const strippedChangeRatio = (qpe.changeRatio ?? '').replaceAll(',', '');
    const qpeChangeRatio = Number.isNaN(parseFloat(strippedChangeRatio))
      ? null
      : (parseFloat(strippedChangeRatio) / 100).toFixed(3);
    return {
      ...qpe,
      changeRatio: qpeChangeRatio,
    };
  });
  // TODO #2219: Need a NewQuoteFromForm wrapper for this case
  const wireQuote = new Quote(
    _.merge(Quote.zero(), {
      ...values,
      products,
      priceEscalations,
    }),
  );
  return wireQuote;
};

type canEditCurrentRevisionProps = {
  ctx: SlabContextType;
  savedQuoteStatus: QuoteStatus | undefined;
};

/**
 * CanEditCurrentRevision returns true if the user can save changes to this
 * Quote, and false if they must "save as new" to create a new Quote revision
 * upon making changes.
 *
 * A user can edit a quote if quote approvals are not enabled, or if the user
 * is a Manager, or if the quote's status does not indicate manager approval.
 */
export const CanEditCurrentRevision = ({
  ctx,
  savedQuoteStatus,
}: canEditCurrentRevisionProps): boolean =>
  !ctx.userInfo.tenant.requiresQuoteApprovals ||
  ctx.userInfo.hasRoles([Enums.RoleName.Manager]) ||
  savedQuoteStatus === undefined ||
  !savedQuoteStatus.isApprovedToSend;

type QuoteDrawerProps = Omit<SharedDrawerOverrideProps<Quote>, 'resourceId' | 'onSaveAsNew'> & {
  resourceId: string | null;
  submitType: QuoteSubmitType;

  /** @default 'Quote Information' */
  initialSection?: QuoteSection;
};

const QuoteDrawerPage = ({
  resourceId,
  isOpen,
  setIsOpen,
  initialSection = 'Quote Information*',
  onSave,
  submitType,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined<Quote>,
}: QuoteDrawerProps): JSX.Element | null => {
  const ctx = React.useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  // We prefer `useRef` here because it allows for immediate mutable changes to the
  // state. If we use `useState` we see "delays" between setting values vs. rendering,
  // which causes form-values to fall out-of-sync with state-values.
  const curProductsRef = React.useRef<Record<number, Product | null>>({});
  const isNew = resourceId === null;

  const createNew = useSlabMutation('POST quote', {
    onError,
  });
  const updateExisting = useSlabMutation('PUT quote by ID', {
    onSuccess: onSave,
    onError,
  });

  const {
    isLoading: isLoadingQuote,
    isError: isErrorQuote,
    data: maybeQuote,
  } = useSlabQuery(
    'GET quote by ID',
    {
      pathParams: {
        id: resourceId ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingQuoteProducts,
    isError: isErrorQuoteProducts,
    data: quoteProducts,
  } = useSlabQuery(
    'GET quote products by quote ID',
    {
      pathParams: {
        id: resourceId ?? '',
      },
    },
    { enabled: !isNew },
  );

  // Once/if quote products load, or are provided via ensureDefined, set them at the drawer state level.
  React.useEffect(() => {
    // When the drawer closes, clear the current state values.
    if (!isOpen) {
      curProductsRef.current = {};
      return;
    }

    // If the quote is new, use the quote products from context
    if (isNew) {
      const quote = ensureDefined(undefined);
      const initialProductsByIndex = ArrayToIndexedRecord(quote.products ?? [], (qp) => qp.product);
      curProductsRef.current = initialProductsByIndex;
      return;
    }

    // An existing quote is being loaded, so handle quote products.
    if (isLoadingQuoteProducts || isErrorQuoteProducts || quoteProducts === undefined) {
      return;
    }
    const initialProductsByIndex = ArrayToIndexedRecord(quoteProducts ?? [], (qp) => qp.product);
    curProductsRef.current = initialProductsByIndex;
  }, [quoteProducts, isOpen]);

  const isLoading = isLoadingQuote || isLoadingQuoteProducts;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorQuote || isErrorQuoteProducts;

  if (isError) {
    return <div>ERROR</div>;
  }

  const quote = ensureDefined(
    maybeQuote === undefined
      ? undefined
      : NewQuote({
          ...maybeQuote,
          products: quoteProducts,
        }),
  );

  const formikQuote = FormikQuote(quote, usesDispatchCustomer, submitType);

  const canEditCurrentRevision = CanEditCurrentRevision({
    ctx,
    savedQuoteStatus: quote?.status,
  });
  // If the current user cannot edit the current revision, they also cannot
  // save a new revision with the existing status. We clear the status from the
  // form data so that validation makes it more obvious that the user needs to
  // choose a different status.
  if (formikQuote.status.id !== '' && !canEditCurrentRevision) {
    formikQuote.status = { id: '', option: null };
  }

  const drawerTitle = ((): string => {
    if (submitType === 'saveAsNewRevision') {
      return 'Create a quote revision';
    }
    if (isNew || submitType === 'saveAsNewQuote') {
      return 'Create a quote';
    }
    return 'Edit a quote';
  })();

  return (
    <Formik
      validationSchema={QuoteSchemaFormik}
      initialValues={formikQuote}
      // Show pre-existing "UOM issue" product errors before interacting with that part of the form.
      validateOnMount={!isNew}
      initialTouched={{
        mainProducts: quoteProducts
          ?.filter((p) => p.kind === Enums.QuoteProductKind.Primary)
          .map(() => ({
            product: {
              option: true,
              incompatibleMixBatchUnits: true,
              allowIncompatibleMixBatchUnits: true,
            },
          })),
        additionalProducts: quoteProducts
          ?.filter((p) => p.kind === Enums.QuoteProductKind.Additional)
          .map(() => ({
            product: {
              option: true,
              incompatibleMixBatchUnits: true,
              allowIncompatibleMixBatchUnits: true,
            },
          })),
      }}
      onSubmit={async (values): Promise<void> =>
        onSubmitHandler({
          createNew,
          formikToWire: formikQuoteToWire,
          updateExisting,
          values,
          wireSchema: QuoteSchemaWire,

          createNewOptions: {
            onSuccess: onSave,
          },
          shouldCreateNew: values.id === NIL_UUID,
        })
      }
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {drawerTitle}
          </Typography>
          <QuoteDrawerSections initialSection={initialSection} curProductsRef={curProductsRef} />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikQuote });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikQuote });

                  curProductsRef.current = {};
                }}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={async (): Promise<void> => {
                  formikBag.submitForm();
                }}
                disabled={
                  !canEditCurrentRevision ||
                  !(
                    formikBag.dirty ||
                    formikBag.touched.mainProducts !== undefined ||
                    formikBag.touched.additionalProducts !== undefined ||
                    createNew.isPending ||
                    updateExisting.isPending
                  )
                }
              />
            </Grid>
          </Grid>
          <Typography marginTop='12px'>
            To save, address any highlighted issues in red first.
          </Typography>
        </Box>
      )}
    </Formik>
  );
};

export const QuoteDrawer = (props: QuoteDrawerProps): JSX.Element => (
  <SlabDrawer
    isOpen={props.isOpen}
    // Required in the scenario a user closes then reopens the same drawer. Product state
    // will not be updated due to single-hook rendering values.
    slideProps={{
      unmountOnExit: true,
    }}
    paperProps={{
      sx: {
        width: '80rem',
        maxWidth: '100%',
      },
    }}
  >
    <QuoteDrawerPage {...props} />
  </SlabDrawer>
);
